import format from 'date-fns/format'
import moment from "moment"
export const formatDate = (date, dateFormat = 'dd.MM.yyyy HH:mm:SS') => {
    return format(new Date(date), dateFormat)
}

export const toSecondsStart = (date, formatDate = 'YYYY-MM-DD HH:mm') => {
    return moment(date, formatDate).unix()
}

export const toSecondsEnd = (date, formatDate = 'YYYY-MM-DD HH:mm') => {
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
        return moment(date, formatDate).endOf('day').unix() 
    } else return moment(date, formatDate).unix()
}
